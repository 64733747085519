import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import http from './http/http'
import session from './session/session'

// axios 拦截器
import './http/axios'

// 对请求方式 进行全局注册
app.config.globalProperties.$http = http
app.config.globalProperties.$session = session

app.mount('#app')
