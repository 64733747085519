<template>
<div style="margin-top: -26px;">
  <div style="position: fixed;width: 100%;max-width: 720px;">
    <div class="select">
      <select v-model="kind">
        <option value="0">
          香港六合彩
        </option>
        <option value="1">
          天天澳门彩
        </option>
      </select>
      <select v-model="year">
        <option v-for="y in 131" :key="y" :value="y+1969">{{y+1969}}年</option>
      </select>
      <select v-model="month">
        <option v-for="m in 12" :key="m" :value="m">{{m}}月</option>
      </select>
    </div>
    <p class="ti">搅珠日期对照表 <span style="font-weight: 800;color: #03a9f4;">蓝色背景</span>为开奖日期</p>
  </div>
  <div style="height:48px"></div>
  <div style="padding:0 1%">
    <img style="width:100%;display: block;" src="/static/rili_01.png" />
    <div class="main">
      <div class="table" v-if="isshow">
        <div style="font-weight: 900;color:#f44336" v-for="n in weeks" :key="n">{{n}}</div>
        <div v-for="n in kong" :key="n"></div>
        <div 
        v-for="(v,i) in data" :key="i" 
        :style="kind==1?'background: #afe7ff;':''"
        :class="calclass(i)" 
        @click="setinfo(i)"
        >
          <div class="day">{{i+1}}</div>
          <div :style="'color:'+daycolor(i)">{{nongli(i)}}</div>
        </div>
        <div v-for="n in kongh" :key="n"></div>
      </div>
      <div class="info" v-if="isshow">
        <p>
          <span style="color:#f00;font-weight:800" v-show="iy==ny&&im==nm&&id==nd">今天</span>
          {{iy}}年 {{im}}月 {{id}}日 星期{{dayinfo.week}}
        </p>
        <p>
          农历：{{dayinfo.nongliyue}}{{dayinfo.nongliri}} &nbsp;<span style="color:#f00">{{dayinfo.shuxiang}}</span>年 {{dayinfo.shujiushufu}}
        </p>
        <p v-show="dayinfo.nianganzhi">
          干支：{{dayinfo.nianganzhi}}年 {{dayinfo.yueganzhi}}月 {{dayinfo.riganzhi}}日
        </p>
        <p v-show="dayinfo.wuxing">
          五行：<span style="color: #ff9800;">{{dayinfo.wuxing}}</span>
        </p>
        <p v-show="dayinfo.chongsha">
          冲煞：<span style="color: #7629ff;">{{dayinfo.chongsha}}</span>
        </p>
        <p v-show="dayinfo.baiji">
          彭祖百忌：{{dayinfo.baiji}}
        </p>
        <p v-show="dayinfo.jishen">
          吉神：<span style="border: 1px solid #f00;color: #f00;">{{dayinfo.jishen}}</span>
        </p>
        <p v-show="dayinfo.xiongshen">
          凶神：<span style="border: 1px solid #3f85ff;color: #64bb00;">{{dayinfo.xiongshen}}</span>
        </p>
        <p v-show="dayinfo.yi">
          宜：{{dayinfo.yi}}
        </p>
        <p v-show="dayinfo.ji">
          忌：{{dayinfo.ji}}
        </p>
      </div>
    </div>
    <img style="width:100%" src="/static/rili_03.png" />
  </div>
</div>
</template>

<script>
export default {
  name: 'Date',
  data(){
    return{
      api:'https://ls.kjkj.fit',
      // api:'',
      weeks:['日','一','二','三','四','五','六'],
      kind:0,
      year:(new Date()).getFullYear(),
      ny:(new Date()).getFullYear(),
      iy:(new Date()).getFullYear(),

      month:(new Date()).getMonth()+1,
      nm:(new Date()).getMonth()+1,
      im:(new Date()).getMonth()+1,
      
      day:(new Date()).getDate(),
      nd:(new Date()).getDate(),
      id:(new Date()).getDate(),

      kong:0,
      kongh:0,
      dayinfo:[],
      data:[],
      isshow:false,
      isgetdayinfo:false
    }
  },
  created(){
    let type = window.location.search;
    switch (type) {
        case '?amdate':
            this.kind = 1;
            break;
        case '?am':
            this.kind = 1;
            break;
        default:
            this.kind = 0;
            break;
    }

    this.first_setdata();
  },
  watch:{
    year:function(){
      this.setdata()
    },
    month:function(){
      this.setdata()
    },
    day:function(){
      this.setdata()
    }
  },
  methods:{
    daycolor:function(i){
      let d = this.data[i];
      if(d['teshujieri']){
        return '#ff9800'
      }
      if(d['gonglijieri']){
        return '#f00'
      }
      if(d['nonglijieri']){
        return '#f0f'
      }
      if(d['jieqi']){
        return '#15bb2b'
      }
      return '#6c6c6c'
    },
    nongli:function(i){
      let d = this.data[i];
      if(d['teshujieri']){
        return d['teshujieri']
      }
      if(d['gonglijieri']){
        return d['gonglijieri']
      }
      if(d['nonglijieri']){
        return d['nonglijieri']
      }
      if(d['jieqi']){
        return d['jieqi']
      }
      return d['nongliri']
    },
    calclass:function(i){
      let res = '';
      if(i+1==this.id&&this.year==this.iy&&this.month==this.im){
        res += ' act';
      }
      if(i+1==this.nd&&this.ny==this.year&&this.nm==this.month){
        res += ' tday';
      }
      if(this.data[i]['iskj']){
        res += ' kj';
      }
      return res;
    },
    setinfo:function(i){
      this.iy = this.year;
      this.im = this.month;
      this.id = i+1;
      this.dayinfo = this.data[i];
    },
    caldata:function(d,kd){
      kd.forEach(v => {
        d[v-1]['iskj'] = true;
      });
      this.data = d;
      if(this.isgetdayinfo==false){
        this.dayinfo = this.data[this.day-1];
        this.isgetdayinfo = true;
      }
      this.kong = this.weeks.indexOf(d[0].week);
      this.kongh = Math.ceil((d.length+this.kong)/7)*7-(d.length+this.kong);
      this.isshow = true;
    },
    showdata:function(data,ym){
      let kjdate = JSON.parse(this.$session.get('kjdate_'+ym));
      if(kjdate){
        this.caldata(data,kjdate.str.split(','));
      }else{
        this.$http.get(this.api+'/kjdate?ym='+ym,true).then((result) => {
          if(result.data.data!==null){
            this.caldata(data,result.data.data.str.split(','));
            this.$session.set('kjdate_'+ym,JSON.stringify(result.data.data));
          }else{
            this.caldata(data,[]);
          }
        });
      }
    },
    setdata:function(){
      this.isshow = false;
      let ym = this.year+''+this.month;
      if(this.month<10){
        ym = this.year+'0'+this.month;
      }
      let data = JSON.parse(localStorage.getItem('wnl_'+ym));
      if(data){
        this.showdata(data,ym);
      }else{
        this.getdata(ym);
      }
    },
    getdata:function(ym){
      this.$http.post(this.api+'/wnl',{'ym':ym},true).then((result) => {
          if(result.data.code==1){
            localStorage.setItem('wnl_'+ym,JSON.stringify(result.data.data));
            this.showdata(result.data.data,ym);
          }else{
            console.log('请求失败！');
          }
        });
    },
    first_setdata:function(){
      let ym = this.year+''+this.month;
      if(this.month<10){
        ym = this.year+'0'+this.month;
      }
      let data = JSON.parse(localStorage.getItem('wnl_'+ym));
      if(data){
        this.showdata(data,ym);
      }else{
        this.$http.post(this.api+'/wnl',{'ym':ym},true).then((result) => {
          if(result.data.code==1){
            localStorage.setItem('wnl_'+ym,JSON.stringify(result.data.data));
            this.showdata(result.data.data,ym);
          }else{
            console.log('请求失败！');
          }
        });
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
*{
  margin: 0;padding: 0;list-style: none;box-sizing: border-box;
}
.main{
  background: url('/static/rili_02.png');
  background-size: 100% 100%;
}
.select{
  height:25px;display: flex;justify-content: space-between;
}
.select select{
  width: 33.3%;
  padding: 3px 0;
  text-align: center;
  border: 1px solid #03a9f4;
  background: #fff;
  outline: none;
}
.table{
  display: flex;
  flex-wrap: wrap;
  font-size:14px;
  justify-content: space-between;
}
.table>div{
  width: 14%;
  padding: 5px 0;
  cursor: pointer;
  border: 1px solid #fff;
}
.table .day{
  font-weight: 600;
  font-size: 1.1rem;
}
.table .tday .day{
  background: #f00;
  color:#fff;
}
.table .act{
  border-color: #f00;
}
.table .kj {
  background: #afe7ff;
}
.ti{
  background: #ffeb3b;
}
.info{
  padding: .4rem;
  font-weight: 800;
  font-family: '楷体';
  line-height: 1.2rem;
}
.info>p{
  margin: .2rem 0;
}
</style>
