<template>
  <div class="main">
    <ul class='th'>
      <li style="padding:0">
        <select style="width: 100%;height: 100%;text-align: center;" @change="changeHm($event.target.value)">
          <option v-for="(v,i) in hm" :key="i" :value='i'>
            {{v}}
          </option>
        </select>
      </li>
      <li v-for='(v,i) in th' :key="i" :class="id==i?'active':''"  @click='changeTi(i)' v-show="i>0">
        {{v}}
      </li>
    </ul>
    <div v-if="data.length==0" class='jiazai'>
      加载数据中……
    </div>
    <div v-if="data.length!==0" class='tongji'>
      <div class='btn'>
        <button @click="sortfc(1)">按比例排序</button>
        <button @click="sortfc(2)">按期数排序</button>
      </div>
      <transition-group name='line'>
        <div class='line' v-for='v in tlist' :key="v.num">
          <div class='lf'>
            <div class='num' v-show="id==0"><span :class="'ball ball'+hms[3][parseInt(v.num)-1]">{{v.num}}</span></div>
            <div class='item' v-show="id!=0">{{v.num}}</div>
            <div class='bf'>{{v.lx}}</div>
          </div>
          <div class='ri'>
            <div class='ri1'>
              <div class='tu' :style="{'width': (v.all/maxnum*80).toFixed(1)+'%' }"></div>
              <span style="font-weight:600">{{v.all}}次</span>
            </div>
            <div>最新开出：{{v.lqi==0?'无':v.lqi+'期'}}</div>
          </div>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tongji',
  props: {
    data: Array
  },
  data(){
    return{
      maxnum:0,
      id:0,
      hmid:0,
      hm:['特码','平一','平二','平三','平四','平五','平六'],
      th:['号码','生肖','五行','波色','头数','尾数','合数','大小','单双','五门','七段','半波'],
      cals:[
        ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49'],
        ['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪'],
        ['金','木','水','火','土'],
        ['红','绿','蓝'],
        ['0头','1头','2头','3头','4头'],
        ['0尾','1尾','2尾','3尾','4尾','5尾','6尾','7尾','8尾','9尾'],
        ['合01','合02','合03','合04','合05','合06','合07','合08','合09','合10','合11','合12','合13'],
        ['小','大'],
        ['单','双'],
        ['1门','2门','3门','4门','5门'],
        ['1段','2段','3段','4段','5段','6段','7段'],
        ['红小','蓝小','绿小','红大','蓝大','绿大']
      ],
      hms:[[],[],[],
        [0,0,2,2,1,1,0,0,2,2,1,0,0,2,2,1,1,0,0,2,1,1,0,0,2,2,1,1,0,0,2,1,1,0,0,2,2,1,1,0,2,2,1,1,0,0,2,2,1],//波色3
        [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4],//头数4
        [1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9],//尾数5
        [0,1,2,3,4,5,6,7,8,0,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,10,2,3,4,5,6,7,8,9,10,11,3,4,5,6,7,8,9,10,11,12],//合数6
        [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],//大小7
        [0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0],//单双8
        [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4],//门数9
        [0,0,0,0,0,0,0,1,1,1,1,1,1,1,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6],//段数10
        [0,0,1,1,2,2,0,0,1,1,2,0,0,1,1,2,2,0,0,1,2,2,0,0,4,4,5,5,3,3,4,5,5,3,3,4,4,5,5,3,4,4,5,5,3,3,4,4,5]//半波11
      ],
      tlist:[]
    }
  },
  created(){
    this.tongji(this.hmid,this.id);
  },
  watch:{
    data(){
      this.tongji(this.hmid,this.id);
    }
  },
  methods:{
    //号码类型切换
    changeHm(i){
      this.hmid = i;
      // this.id = 0;
      this.tongji(i,this.id);
    },
    //统计类型切换
    changeTi(i){
      if(this.id != i){
        this.id = i;
      }else{
        this.id = 0;
      }
      this.tongji(this.hmid,this.id);
    },
    //统计类型
    tongji(i=0,type){
      let data = this.data;
      let tms = [];
      let qis = [];
      for(let j=0;j<data.length;j++){
          tms.push( this.numres(data[j],type,i) );
          qis.push( data[j].year + data[j].qishu );
      }
      if(type>11){
        type = 0
      }
      this.calculate(tms,qis,this.cals[type]);
    },
    //返回类型数据
    numres(d,t,i){
      let num = d.num.split(',');
      let sx = d.shengxiao.split(',')[i-1];
      let wx = d.wuxing.split(',')[i-1];
      let tm = parseInt(num[i-1]);
      if(i==0){
        tm = parseInt(num[6]);
        sx = d.shengxiao.split(',')[6];
        wx = d.wuxing.split(',')[6];
      }
      let res = tm;
      switch (t) {
        case 0:
          res = tm;
          break;
        case 1:
          res = sx;
          break;
        case 2:
          res = wx;
          break;
        case 3:case 4:case 5:case 6:case 7:case 8:case 9:case 10:case 11:
          res = this.restm(tm,t);
          break;
      }
      return res;
    },
    //号码转换
    restm(n,t){
      let a = this.hms;
      let res = this.cals[t][a[t][n-1]];
      return res;
    },
    //排序
    sortfc(t){
      function sfc(a,b){  
          if(t==1){
            return b.all-a.all
          }
          if(t==2){
            return b.lqi-a.lqi
          }
      }
      this.tlist.sort(sfc);
    },
    //最终计算
    calculate(tm,qi,cals){
      let res = [];
      let maxnum = 0;
      for(let i=0;i<cals.length;i++){
          var count = 0, lastqi = 0;
          for(let j=tm.length-1;j>=0;j--){
              if(tm[j] == cals[i]){
                  count += 1;
                  lastqi = qi[j];
              }
          }
          let num = cals[i]; 
          let lx = (count/tm.length*100).toFixed(1)+'%';
          let lqi = lastqi;
          let all = count;
          maxnum = maxnum<count?count:maxnum;
          res.push({num:num,lx:lx,lqi:lqi,all:all});
      }
      this.maxnum = maxnum;
      this.tlist = res;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.th{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.th .active{
  background: #fff;
  color: #ff6500;
  font-weight: 600;
}
.th li{
  list-style: none;
  width: 16%;
  margin: 1px;
  background: #51c01b;
  padding: 2px 0;
  color: #fff;
}
.tongji{
  border: 2px solid #ec922b;
  padding: 2px;
}
.tongji .line{
  display: flex;
  margin: 8px 0;
}
.tongji .line .lf{
  width: 15%;
  border: 1px solid #ff8100;
}
.tongji .line .lf div{
  padding: 3px 0;
}
.tongji .line .lf .item{
  background: #ffe5a3;
  font-weight: 600;
  padding: 5px 0;
}
.tongji .line .lf .num{
  border-bottom: 1px solid #c2c2c2;
}
.tongji .line .ri{
  width: 85%;
  text-align: left;
}
.tongji .line .ri .ri1{
  display:flex;align-items: center;height:32px
}
.tongji .line .ri .tu{
  background: linear-gradient(#deffe0,#16cc0b);
  height: 20px;
  transition: width .5s;
  -moz-transition: width .5s;
  -webkit-transition: width .5s;
  -o-transition: width .5s;
}
.btn button{
  margin: 0 10%;
  width: 30%;
  padding: 5px 0;
}
</style>
