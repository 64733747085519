<template>
  <div class="main">
    <ul class='th'>
      <li style="padding:0">
        <select style="width: 100%;height: 100%;text-align: center;" @change="changeHm($event.target.value)">
          <option v-for="(v,i) in hm" :key="i" :value='i'>
            {{v}}
          </option>
        </select>
      </li>
      <li v-for='(v,i) in th' :key="i" :class="id==i?'active':''"  @click='changeTi(i)' v-show="i>0">
        {{v}}
      </li>
    </ul>
    <div v-if="data.length==0" class='jiazai'>
      加载数据中……
    </div>
    <transition>
    <div v-if="data.length!==0" class='tongji'>
      <p style="text-align:center">点击表格标题可排序</p>
      <table>
        <thead>
          <tr>
            <th>{{th[id]}}</th>
            <th @click="sortfc(1)">连续遗漏<span :class="btnid!==1?'btn':'btn btn-s'"></span></th>
            <th @click="sortfc(2)">上次开出期数<span :class="btnid!==2?'btn':'btn btn-s'"></span></th>
            <th @click="sortfc(3)">总遗漏<span :class="btnid!==3?'btn':'btn btn-s'"></span></th>
          </tr>
        </thead>
        <tbody>
          <transition-group name='line'>
          <tr v-for='v in tlist' :key="v.num">
              <th v-show="id==0"><span :class="'ball ball'+hms[3][parseInt(v.num)-1]">{{v.num}}</span></th>
              <th style="font-weight: 600;" v-show="id!=0">{{v.num}}</th>
              <th>{{v.lx}}次</th>
              <th>{{v.lqi==0?'无':v.lqi}}</th>
              <th>{{v.all}}次</th>
          </tr>
          </transition-group>
        </tbody>
      </table>
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Yilou',
  props: {
    data: Array
  },
  data(){
    return{
      btnid:2,
      id:0,
      hmid:0,
      hm:['特码','平一','平二','平三','平四','平五','平六'],
      th:['号码','生肖','五行','波色','头数','尾数','合数','大小','单双','五门','七段','半波'],
      cals:[
        ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49'],
        ['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪'],
        ['金','木','水','火','土'],
        ['红','绿','蓝'],
        ['0头','1头','2头','3头','4头'],
        ['0尾','1尾','2尾','3尾','4尾','5尾','6尾','7尾','8尾','9尾'],
        ['合01','合02','合03','合04','合05','合06','合07','合08','合09','合10','合11','合12','合13'],
        ['小','大'],
        ['单','双'],
        ['1门','2门','3门','4门','5门'],
        ['1段','2段','3段','4段','5段','6段','7段'],
        ['红小','蓝小','绿小','红大','蓝大','绿大']
      ],
      hms:[[],[],[],
        [0,0,2,2,1,1,0,0,2,2,1,0,0,2,2,1,1,0,0,2,1,1,0,0,2,2,1,1,0,0,2,1,1,0,0,2,2,1,1,0,2,2,1,1,0,0,2,2,1],//波色3
        [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4],//头数4
        [1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9],//尾数5
        [0,1,2,3,4,5,6,7,8,0,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,10,2,3,4,5,6,7,8,9,10,11,3,4,5,6,7,8,9,10,11,12],//合数6
        [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],//大小7
        [0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0],//单双8
        [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4],//门数9
        [0,0,0,0,0,0,0,1,1,1,1,1,1,1,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6],//段数10
        [0,0,1,1,2,2,0,0,1,1,2,0,0,1,1,2,2,0,0,1,2,2,0,0,4,4,5,5,3,3,4,5,5,3,3,4,4,5,5,3,4,4,5,5,3,3,4,4,5]//半波11
      ],
      tlist:[]
    }
  },
  created(){
    this.tongji(this.hmid,this.id);
  },
  watch:{
    data(){
      this.btnid = 2;
      this.tongji(this.hmid,this.id);
    },
    tlist(){
      this.btnid = 2;
    }
  },
  methods:{
    //号码类型切换
    changeHm(i){
      this.hmid = i;
      this.tongji(i,this.id);
    },
    //统计类型切换
    changeTi(i){
      if(this.id != i){
        this.id = i;
      }else{
        this.id = 0;
      }
      this.tongji(this.hmid,this.id);
    },
    //统计类型
    tongji(i=0,type){
      let data = this.data;
      let tms = [];
      let qis = [];
      for(let j=0;j<data.length;j++){
          tms.push( this.numres(data[j],type,i) );
          qis.push( data[j].year + data[j].qishu );
      }
      if(type>11){
        type = 0
      }
      this.calculate(tms,qis,this.cals[type]);
    },
    //返回类型数据
    numres(d,t,i){
      let num = d.num.split(',');
      let sx = d.shengxiao.split(',')[i-1];
      let wx = d.wuxing.split(',')[i-1];
      let tm = parseInt(num[i-1]);
      if(i==0){
        tm = parseInt(num[6]);
        sx = d.shengxiao.split(',')[6];
        wx = d.wuxing.split(',')[6];
      }
      let res = tm;
      switch (t) {
        case 0:
          res = tm;
          break;
        case 1:
          res = sx;
          break;
        case 2:
          res = wx;
          break;
        case 3:case 4:case 5:case 6:case 7:case 8:case 9:case 10:case 11:
          res = this.restm(tm,t);
          break;
      }
      return res;
    },
    //号码转换
    restm(n,t){
      let a = this.hms;
      let res = this.cals[t][a[t][n-1]];
      return res;
    },
    //排序
    sortfc(t){
      let data = this.tlist;
      function sfc(a,b){  
          if(t==1){
            return b.lx-a.lx
          }
          if(t==2){
            return b.lqi-a.lqi
          }
          if(t==3){
            return b.all-a.all
          }
      }
      this.btnid = t;
      data.sort(sfc);
      this.tlist = data;
    },
    //最终计算
    calculate(tm,qi,cals){
      let res = [];
      for(let i=0;i<cals.length;i++){
          var count = 0, lianxu = 0, lastqi = 0;
          for(let j=tm.length-1;j>=0;j--){
              if(tm[j] == cals[i]){
                  count += 1;
                  lianxu = 0;
                  lastqi = qi[j];
              }else{
                  lianxu += 1;
              }
          }
          let num = cals[i]; 
          let lx = lianxu;
          let lqi = lastqi;
          let all = tm.length-count;
          res.push({num:num,lx:lx,lqi:lqi,all:all});
      }
      function sfc(a,b){
          return b.lqi-a.lqi
      }
      res.sort(sfc);
      this.tlist = res;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.th{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.th .active{
  background: #fff;
  color: #ff6500;
  font-weight: 600;
}
.th li{
  list-style: none;
  width: 16%;
  margin: 1px;
  background: #ffaf55;
  padding: 2px 0;
  color: #fff;
}
th,tr{
    font-weight: 500;
    padding: 10px 0;
    border: 1px solid #ffc300;
}
.tongji{
  border: 2px solid #ff8100;
}
.tongji table{
  width: 100%;
  border-collapse: collapse;
}
.btn {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 15px solid #8d8d8d;
  display: inline-block;
  vertical-align: text-bottom;
}
.btn-s{
  border-top: 15px solid #ff665b;
}
</style>
