<template>
  <div class="main">
    <div v-if="data.length==0" class='jiazai'>
      加载数据中……
    </div>
    <div v-if="data.lastqi!==0">
        <transition-group name='line'>
        <div v-for='v in data' :key="v" class='his-line'>
            <div class="his-qid">
                <div class="pt">{{v.date}}</div>
                <div class="qn">{{v.qishu}}期</div>
            </div>
            <div class="his-nb">
                <ul>
                    <li v-for="(n,j) in v.num.split(',')" :key="j" v-show="j<6">
                        <div :class="'nb c'+n">{{n}}</div>
                        <p class="pro">{{v.shengxiao.split(',')[j]}}/{{v.wuxing.split(',')[j]}}</p>
                    </li>
                    <li>
                        <div style="line-height: 32px;">+</div>
                    </li>
                    <li>
                        <div :class="'nb c'+v.num.split(',')[6]">{{v.num.split(',')[6]}}</div>
                        <p class="pro">{{v.shengxiao.split(',')[6]}}/{{v.wuxing.split(',')[6]}}</p>
                    </li>
                </ul>
            </div>
        </div>
        </transition-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'History',
  props: {
    data: Array
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.his-line{
    display: flex;
    padding: 8px 2px;
    border-bottom: 1px solid #8fdbff;
}
.his-qid {
    color: #8b8b8b;
    width: 20%;
    font-size: 14px;
    width: 20%;
    line-height: 20px;
}
.his-qid .qn{
    font-size: 16px;
    line-height: 32px;
    color: #373737;
    font-weight: 600;
    letter-spacing: 1px;
}
.his-nb{
    width: 80%;
}
ul {
    display: flex;
    justify-content: space-around;
}
ul li div{
    font-size: 16px;
}
.pro {
    font-size: 14px;
}
.nb{
    width: 32px;
    height: 32px;
    background-size: contain;
    line-height: 32px;
    font-weight: 600;
}
</style>
