<template>
  <div class="main">
    <ul class='th'>
      <li style="padding:0">
        <select style="width: 100%;height: 100%;text-align: center;" @change="changeHm($event.target.value)">
          <option v-for="(v,i) in hm" :key="i" :value='i'>
            {{v}}
          </option>
        </select>
      </li>
      <li v-for='(v,i) in th' :key="i" :class="id==i?'active':''"  @click='changeTi(i)' v-show="i>0">
        {{v}}
      </li>
    </ul>
    <div style="position: relative;height: 50px;display:flex;border-top: 1px solid #b60909;">
      <div class='lf' style="line-height:50px">期数</div>
      <div class='ri' v-if="id==0" style="justify-content: space-between;align-items:center;">
        <div v-for='v in numcals' :key="v">
          {{v}}
        </div>
      </div>
      <div class='ri' v-if="id!==0">
        <div :class="ballsize(id)+' ballti'" :style="itemlf(id,i)" v-for='(v,i) in cals[id]' :key="i"  v-show='id!==6'>
          {{v}}<p>|</p>
        </div>
        <div :class="ballsize(id)+' ballti'" :style="itemlf(id,i)" v-for='(v,i) in heshucals' :key="i"  v-show='id==6'>
          <p>合</p>{{v}}<p style="font-size:5px">|</p>
        </div>
      </div>
    </div>
    <div v-if="data.length==0" class='jiazai'>
      加载数据中……
    </div>
    <transition>
    <div v-show="divshow">
      <div class='tongji'>
        <div class='line' v-for='v in tlist' :key="v">
          <div class='lf'>
            {{v.year}}<p><b>{{v.qi}}</b></p>
          </div>
          <div class='ri'>
            <div :class="ballsize(id)+' ball'" :style="{'background':bcolor(id,v.i),'left':v.lfvalue+'%','top':v.topvalue}">
              {{v.tm}}
            </div>
          </div>
        </div>
      </div>
      <canvas id="canvas" ref="canvas" :width="cvswidth" :height="cvsheight" :style="'position: absolute;transform: translate(-50%,0);z-index: 2;width:'+cvswidth+'px;max-width:720px;height:'+cvsheight+'px'"></canvas>
    </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Zoushi',
  props: {
    data: Array
  },
  data(){
    return{
      id:0,
      hmid:0,
      hm:['特码','平一','平二','平三','平四','平五','平六'],
      th:['号码','生肖','五行','波色','头数','尾数','合数','大小','单双','五门','七段','半波'],
      numcals:['01','10','20','30','40','49'],
      heshucals:['01','02','03','04','05','06','07','08','09','10','11','12','13'],
      cals:[
        ['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31','32','33','34','35','36','37','38','39','40','41','42','43','44','45','46','47','48','49'],
        ['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪'],
        ['金','木','水','火','土'],
        ['红','绿','蓝'],
        ['0头','1头','2头','3头','4头'],
        ['0尾','1尾','2尾','3尾','4尾','5尾','6尾','7尾','8尾','9尾'],
        ['合01','合02','合03','合04','合05','合06','合07','合08','合09','合10','合11','合12','合13'],
        ['小','大'],
        ['单','双'],
        ['1门','2门','3门','4门','5门'],
        ['1段','2段','3段','4段','5段','6段','7段'],
        ['红小','蓝小','绿小','红大','蓝大','绿大']
      ],
      hms:[[],[],[],
        [0,0,2,2,1,1,0,0,2,2,1,0,0,2,2,1,1,0,0,2,1,1,0,0,2,2,1,1,0,0,2,1,1,0,0,2,2,1,1,0,2,2,1,1,0,0,2,2,1],//波色3
        [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4],//头数4
        [1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9],//尾数5
        [0,1,2,3,4,5,6,7,8,0,1,2,3,4,5,6,7,8,9,1,2,3,4,5,6,7,8,9,10,2,3,4,5,6,7,8,9,10,11,3,4,5,6,7,8,9,10,11,12],//合数6
        [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],//大小7
        [0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0,1,0],//单双8
        [0,0,0,0,0,0,0,0,0,1,1,1,1,1,1,1,1,1,2,2,2,2,2,2,2,2,2,3,3,3,3,3,3,3,3,3,3,4,4,4,4,4,4,4,4,4,4,4,4],//门数9
        [0,0,0,0,0,0,0,1,1,1,1,1,1,1,2,2,2,2,2,2,2,3,3,3,3,3,3,3,4,4,4,4,4,4,4,5,5,5,5,5,5,5,6,6,6,6,6,6,6],//段数10
        [0,0,1,1,2,2,0,0,1,1,2,0,0,1,1,2,2,0,0,1,2,2,0,0,4,4,5,5,3,3,4,5,5,3,3,4,4,5,5,3,4,4,5,5,3,3,4,4,5]//半波11
      ],
      tlist:[],
      cvswidth:'',
      cvsheight:'',
      divshow:false
    }
  },
  created(){
    this.cvswidth = window.screen.availWidth;
    this.cvsheight = this.data.length*40;
  },
  mounted(){
    this.tongji(this.hmid,this.id);
  },
  watch:{
    data(){
      this.divshow = false;
      this.cvswidth = window.screen.availWidth;
      this.cvsheight = this.data.length*40;
      this.$nextTick(()=>{
        this.tongji(this.hmid,this.id);
      });
    }
  },
  methods:{
    itemlf(id,i){
      let s = (i+1)/(this.cals[id].length+1)*86+12+'%';
      return {'left':s};
    },
    //大小
    ballsize(type){
      if(type==0||type==1||type==2||type==3||type==7||type==8){
        return 'ballx'
      }else{
        return 'balld'
      }
    },
    //背景颜色
    bcolor(type,i){
      let s = '#f00';
      let c = ['#ff1616','#1cd616','#009aff'];
      let cb = ['red','#1e7cff','#2ec60d','red','#1e7cff','#2ec60d'];
      if(type==0){
        s = c[this.hms[3][i]];
      }
      if(type==3){
        s = c[i];
      }
      if(type==11){
        s = cb[i]
      }
      return s;
    },
    //统计类型
    tongji(i=0,type){
      let data = this.data;
      let tlist = [];
      let wsw = window.screen.availWidth;

      let c = document.getElementById('canvas');
      let ctx = c.getContext("2d");
      ctx.clearRect(0,0,wsw,data.length*40);
      ctx.strokeStyle = '#1819ff';
      ctx.lineWidth = 0.5;

      ctx.beginPath();
      for(let j=0;j<data.length;j++){
          let tm = this.numres(data[j],type,i);
          let year = data[j].year;
          let qi = data[j].qishu;
          let lfv = (tm.i+1)/(this.cals[type].length+1)*86+12;
          let tpv = '20px';
          tlist.push({'year':year,'qi':qi,'tm':tm.v,'i':tm.i,'lfvalue':lfv,'topvalue':tpv});
          if(j>0){
      ctx.moveTo(tlist[j-1].lfvalue/100*wsw,(j-1)*40+20);
      ctx.lineTo(lfv/100*wsw,j*40+20);
          }
      }
      ctx.stroke();

      if(type>11){
        type = 0
      }
      this.tlist = tlist;
      setTimeout(() => {
        this.divshow = true;
      }, 50);
    },
    //返回类型数据
    numres(d,t,i){
      let num = d.num.split(',');
      let sx = d.shengxiao.split(',')[i-1];
      let wx = d.wuxing.split(',')[i-1];
      let tm = parseInt(num[i-1]);
      let hm = num[i-1];
      if(i==0){
        tm = parseInt(num[6]);
        hm = num[6];
        sx = d.shengxiao.split(',')[6];
        wx = d.wuxing.split(',')[6];
      }
      let res = {'v':hm,'i':tm-1};
      switch (t) {
        case 0:
          break;
        case 1:
          res.v = sx;
          res.i = this.cals[1].indexOf(sx);
          break;
        case 2:
          res.v = wx;
          res.i = this.cals[2].indexOf(wx);
          break;
        case 3:case 4:case 5:case 6:case 7:case 8:case 9:case 10:case 11:
          res = this.restm(tm,t);
          break;
      }
      return res;
    },
    //号码转换
    restm(n,t){
      let a = this.hms;
      let res = this.cals[t][a[t][n-1]];
      return {'v':res,'i':a[t][n-1]};
    },
    //号码类型切换
    changeHm(i){
      this.divshow = false;
      this.hmid = i;
      this.tongji(i,this.id);
    },
    //统计类型切换
    changeTi(i){
      this.divshow = false;
      if(this.id != i){
        this.id = i;
      }else{
        this.id = 0;
      }
      this.tongji(this.hmid,this.id);
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.th{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.th .active{
  background: #fff;
  color: #ff6500;
  font-weight: 600;
}
.th li{
  list-style: none;
  width: 16%;
  margin: 1px;
  background: #f95e5f;
  padding: 2px 0;
  color: #fff;
}
.tongji{
  width: 100%;
  max-width: 720px;
  position: absolute;
  background: #fff;
}
.line{
  display: flex;
  height: 40px;
  position: relative;
}
.line:nth-child(2n-1){
  background:#e6f8ff;
}
.lf{
  width: 10%;
  border-right: 1px solid #d7d7d7;
  font-size: 14px;
}
.ri{
  width: 86%;
  margin: 0 2%;
  font-size: 14px;
  display: flex;
  justify-content: space-evenly;
}
.ri .ballti{
  font-size: 14px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}
.ri .ball{
  color: #fff;
  border-radius: 50%;
  font-size: 14px;
  box-shadow: 1px 1px 1px 0px #747474;
  position: absolute;
  transform: translate(-50%, -50%);
  z-index: 3;
}
.ri .ballx{
  width: 24px;
}
.ball.ballx{
  height: 24px;
  line-height: 25px;
}
.ri .balld{
  width: 30px;
}
.ball.balld{
  height: 30px;
  line-height: 32px;
}
</style>
